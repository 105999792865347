.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-inactive:hover {
  cursor: pointer;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.hidden-tab {
  display: none;
}

.hierarchy-container {
  padding: 10px 20px;
}

.loading {
  cursor: wait;
}

.login-container {
  margin: 10% 20%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.all-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sync-buttons-container {
  align-self: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 5px;
  justify-content: end;
  margin-left: auto;
  padding: 5px;
}

.export-container {
  display: flex;
  padding: 10px 0px;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}

#app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#about-tag {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
}

#login-button {
  margin: 10px;
}

#sync-status-container {
  padding: 5px 10px;
}

#sync-status-header.SUCCEEDED {
  color: #2e7d32;
  font-weight: 500;
}

#sync-status-header.FAILED {
  color: #d32f2f;
  font-weight: 500;
}

#sync-status-header.CANCELLED {
  color: #d32f2f;
  font-weight: 500;
}