.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  padding: 5px 0px;
  display: flex;
  column-gap: 1em;
}

table {
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
}

th, td {
  text-align: left;
  border: 0px;
  border-bottom: 1px solid grey;
}

td {
  padding: 0px 10px;
}

th {
  padding: 5px 10px;
  font-weight: bold;
  font-size: 1.1em
}

thead > tr {
  background-color: #d2d2d2;
}

tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody > tr:hover {
  background-color: lightgrey;
}

.different-row  {
  background: #ffb570c0 !important;
}

.different-row:hover  {
  background: #ffa44fe0 !important;
}

.missing-row {
  background: #ff8282c0 !important;
}

.missing-row:hover {
  background: #ff6363e0 !important;
}


.connect-border {
  border-left: 1px solid black;
}

.pagnation {
  padding: 5px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 10px;
}

.page-buttons-container {
  align-self: center;
  align-content: center;
  display: flex;
  margin: 0 0 0 auto;
}

.page-button {
  background-color: #f2f2f2;
  border-radius: 3px;
  border-width: 1px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  height: 25px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  min-width: 0;
}

.page-button:hover:enabled {
  background-color: #e0e0e0;
}

.page-button:disabled {
  color: #33333388;
  cursor: default;
}

.hierarchy-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}