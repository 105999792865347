button {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 3px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 5px 8px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.whitelist-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.flex-row {
  align-items: center;
  column-gap: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  row-gap: .8em;
}

.inputs-container {
  align-items: center;
  column-gap: 1em;
  display: flex;
  flex-direction: row;
}

.input-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.date-picker {
  padding: .5em;
  margin: 0 1em;
}

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  column-gap: 1em;
  display: flex;
  padding: 5px 0px;
}

table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 0px;
  border-bottom: 1px solid grey;
  text-align: left;
}

td {
  padding: 0px 10px;
}

th {
  font-size: 1.1em;
  font-weight: bold;
  padding: 5px 10px;
}

thead > tr {
  background-color: #d2d2d2;
}

tbody > tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody > tr:hover {
  background-color: lightgrey;
}