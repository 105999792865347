

select {
  width: 160px;
  margin: 0 auto;
}

select.hover {
  background-color: rgba(51, 51, 51, 0.05);
}

.button-box {
  margin-left: auto;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

.filters-column {
  display: flex;
  flex-direction: column;
  row-gap: .8em;
  column-gap: .8em;
}

.filters-column.right {
  margin-left: auto;
}

.filters-column.hidden {
  display: none;
}

.filters-row {
  align-items: center;
  column-gap: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0px;
}

.filters-row.end {
  justify-content: end;
}

.comparison-container {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
}

.container-column {
  display: flex;
  flex-direction: column;
}

.container-row {
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1em;
}

.select-container {
  width: 300px;
}

.get-data-button {
  margin-top: auto;
  margin-left: 1em;
}

.comparison-button {
  margin-left: auto;
}

.progress-container {
  height: 10px;
  margin-top: 5px;
}
